import { useContext, useState } from "react";
import { putResyncItems } from "../http";
import { WrappedComponentContext } from "../../WrappedComponent";

const useOnResync = ({ token, chatbotId, dataSourceId, refetch }) => {
  const [loading, setLoading] = useState(false);
  const { setBannerMessage } = useContext(WrappedComponentContext);

  const onResync = async () => {
    setLoading(true);

    try {
      await putResyncItems({ chatbotId, dataSourceId, token });
      await refetch();
      setLoading(false);
    } catch (error) {
      setBannerMessage({
        type: "error",
        title: error.message,
      });
      setLoading(false);
    }
  };

  return { loading, onResync };
};

export default useOnResync;
