const Breadcrumbs = ({ store }) => {
  const { breadcrumbs, updateCurrentBreadcrumb } = store();
  const lastBreadcrumbIndex = breadcrumbs.length - 1;

  const onBreadcrumbClick = (index) => {
    updateCurrentBreadcrumb(index);
  };

  return (
    <div style={{ width: "100%", display: "flex", gap: "4px" }}>
      {breadcrumbs.map((breadcrumb, index) => {
        const isLastBreadcrumb = lastBreadcrumbIndex === index;

        return (
          <div key={breadcrumb.label}>
            <span
              onClick={() => {
                if (isLastBreadcrumb) return;

                onBreadcrumbClick(index);
              }}
              style={{
                color: !isLastBreadcrumb ? "#A24DFF" : "#5F5968",
                cursor: !isLastBreadcrumb ? "pointer" : "default",
                fontSize: "12px",
              }}
            >
              {breadcrumb.label}
            </span>
            {!isLastBreadcrumb && (
              <span style={{ marginLeft: "4px" }}>{"/"}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
