import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const ITEMS_PER_PAGE = 25;

export const DATA_SOURCES_WITH_PAGES = ["CONFLUENCE", "NOTION"];

export const REFETCH_INTERVAL_MS = 20000;

const defaultState = (set, get) => ({
  isQueuedForSyncing: false,
  isLoadingFiles: false,
  isConnected: false,
  isModalVisible: false,
  isReviewMode: false,
  email: "",
  checkedFiles: [],
  currentBreadcrumb: 0,
  breadcrumbs: [],
  dataSourceId: "",
  offset: 0,
  parentId: null,
  setParentId: (value) => set({ parentId: value }),
  connect: (value) => set({ isConnected: true, email: value }),
  disconnect: () => set({ isConnected: false, email: "" }),
  showModal: () => set({ isModalVisible: true }),
  hideModal: () =>
    set({
      isModalVisible: false,
      breadcrumbs: [],
      currentBreadcrumb: 0,
      offset: 0,
      checkedFiles: [],
    }),
  addToBreadcrumb: (label, tree, count) =>
    set((state) => {
      const newBreadcrumbs = [
        ...state.breadcrumbs,
        {
          label,
          tree,
          count,
          parentId: state.parentId,
        },
      ];
      const newCurrentBreadcrumb = newBreadcrumbs.length - 1;

      return {
        breadcrumbs: newBreadcrumbs,
        currentBreadcrumb: newCurrentBreadcrumb,
      };
    }),
  appendToBreadcrumb: (tree, currentBreadcrumb) =>
    set((state) => {
      return {
        breadcrumbs: state.breadcrumbs.map((b, index) => {
          if (index === currentBreadcrumb) {
            return {
              ...b,
              tree: [...b.tree, ...tree],
            };
          }
          return b;
        }),
      };
    }),
  checkUncheckFile: (isChecked, file) => {
    if (isChecked) {
      set((state) => ({
        checkedFiles: state.checkedFiles.filter((f) => f.id !== file.id),
      }));
    } else {
      set((state) => ({
        checkedFiles: [...state.checkedFiles, file],
      }));
    }
  },
  updateCurrentBreadcrumb: (value) =>
    set((state) => {
      const slicedBreadcrumbs = state.breadcrumbs.slice(0, value + 1);
      const currentBreadcrumb = state.breadcrumbs[value];

      return {
        breadcrumbs: slicedBreadcrumbs,
        currentBreadcrumb: value,
        parentId: value.parentId ? value.parentId : null,
        offset:
          currentBreadcrumb.count === currentBreadcrumb.tree.length
            ? 0
            : currentBreadcrumb.tree.length,
      };
    }),
  setIsQueuedForSyncing: (value) => set({ isQueuedForSyncing: value }),
  setReviewMode: (value) => set({ isReviewMode: value }),
  setOffset: (value) => set({ offset: value }),
  setDataSourceId: (value) => set({ dataSourceId: value }),
  setIsLoadingFiles: (value) => set({ isLoadingFiles: value }),
  clearCheckedFiles: () => set({ checkedFiles: [] }),
  getCurrentFolder: () => {
    const { breadcrumbs, currentBreadcrumb } = get();
    return breadcrumbs.find((_, index) => index === currentBreadcrumb);
  },
});

export const useDropboxStore = create(
  devtools(defaultState, { name: "Dropbox" })
);

export const useGoogleDriveStore = create(
  devtools(defaultState, { name: "Google Drive" })
);

export const useBoxStore = create(devtools(defaultState, { name: "Box" }));

export const useConfluenceStore = create(
  devtools(defaultState, { name: "Confluence" })
);

export const useNotionStore = create(
  devtools(defaultState, { name: "Notion" })
);
