import { useCallback, useState } from "react";
import { API_URL } from "../../config";

const useOnDisconnect = ({ token, chatbotId, dataSourceId, store }) => {
  const [loading, setLoading] = useState(false);
  const { disconnect } = store();

  const revokeDatasource = useCallback(async () => {
    try {
      const headers = { Authorization: token };
      setLoading(true);
      const response = await fetch(
        `${API_URL}/carbon_ai/revoke_connection?chat_bot_id=${chatbotId}&data_source_id=${dataSourceId}`,
        {
          method: "DELETE",
          headers,
        }
      );
      await response.json();
      setLoading(false);
    } catch (e) {
      console.log("e", e);
      console.log("Error while revoking connection.");
    }
  }, [chatbotId, dataSourceId, token]);

  const onDisconnect = async () => {
    await revokeDatasource();
    disconnect();
  };

  return { loading, onDisconnect };
};

export default useOnDisconnect;
