import { useRef } from "react";
import { ReactComponent as FolderIcon } from "../../../media/folderIcon.svg";
import { ReactComponent as TickIcon } from "../../../media/tickIcon.svg";
import Breadcrumbs from "./Breadcrumbs";
import { DATA_SOURCES_WITH_PAGES } from "../../store";
import RowLoader from "../RowLoader/RowLoader";
import Loader from "../Loader/Loader";
const SelectScreen = ({ dataSource, store, onBottomScroll, onFolder }) => {
  const { checkedFiles, checkUncheckFile, getCurrentFolder, isLoadingFiles } =
    store();

  const { tree } = getCurrentFolder() ?? { tree: [] };
  const scrollContainerRef = useRef(null);
  const abortControllerRef = useRef(null);
  const hasPagesInsteadOfFiles = DATA_SOURCES_WITH_PAGES.includes(dataSource);

  const onFileClick = (isChecked, file) => {
    const isNotPage = file.item_type !== "PAGE";

    if (file.selected_for_sync) return;

    if (file.is_expandable && isNotPage) {
      onFolder(file);
      return;
    }

    checkUncheckFile(isChecked, file);
  };

  const onScrollCapture = (event) => {
    const target = event.target;

    const totalScrolled = target.clientHeight + target.scrollTop;
    const isBottom = totalScrolled >= target.scrollHeight;

    if (isBottom) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Abort the previous request
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;

      onBottomScroll(controller.signal);
    }
  };

  return (
    <>
      <h2 style={{ fontSize: "20px", fontWeight: 600 }}>
        Select {hasPagesInsteadOfFiles ? "pages" : "files"} to add to knowledge
        base:
      </h2>
      <div style={{ display: "flex", gap: "6px" }}>
        <Breadcrumbs store={store} />
        {isLoadingFiles && <Loader />}
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            height: "544px",
            width: "100%",
            overflowY: "auto",
            marginTop: "10px",
            border: "1px solid #EDEDEF",
            borderRadius: "3px",
            position: "relative",
          }}
          ref={scrollContainerRef}
          onScrollCapture={onScrollCapture}
        >
          {tree &&
            tree.map((file) => {
              const isChecked = checkedFiles.some((f) => f.id === file.id);

              const isDisabled = file.disabled;

              const isExpandable = file.is_expandable;
              const isSelectable = file.is_selectable;
              const isFolderable = ["SPACE", "FOLDER"].includes(file.item_type);

              const shouldRenderFolder = isExpandable && isFolderable;
              const shouldRenderCheckbox = isSelectable && !isFolderable;

              return (
                <div
                  onClick={() => onFileClick(isChecked, file)}
                  style={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                    borderBottom: "1px solid #F6F7F9",
                    height: "40px",
                    opacity: isDisabled ? 0.5 : 1,
                    cursor: isDisabled ? "not-allowed" : "pointer",
                  }}
                  key={file.id}
                >
                  {shouldRenderFolder && (
                    <span
                      style={{
                        marginLeft: "6px",
                      }}
                    >
                      <FolderIcon />
                    </span>
                  )}
                  {shouldRenderCheckbox && (
                    <span
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "1px solid #C0C0C0",
                        borderRadius: "2px",
                        marginLeft: "10px",
                        marginRight: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        ...(isChecked ? { backgroundColor: "#EFE2FF" } : {}),
                      }}
                    >
                      {isChecked && <TickIcon />}
                    </span>
                  )}
                  {file.name}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SelectScreen;
