import { useContext, useState } from "react";
import { getOAuthUrl } from "../http";
import { WrappedComponentContext } from "../../WrappedComponent";

const useOnConnect = ({ token, chatbotId, dataSource, refetch }) => {
  const [loading, setLoading] = useState(false);
  const { setBannerMessage } = useContext(WrappedComponentContext);

  const onConnect = async ({ alertMessage = "", onClose }) => {
    setLoading(true);

    let prompt;

    try {
      if (dataSource === "CONFLUENCE") {
        prompt = window.prompt(
          "Enter your Confluence subdomain: e.g. <your_subdomain>.atlassian.net"
        );

        if (!prompt) {
          setLoading(false);
          return;
        }
      }
      const url = await getOAuthUrl({ chatbotId, dataSource, token, prompt });
      const popup = window.open(
        url,
        "OauthPopup",
        "width=700,height=500,scrollbars=no,resizable=no"
      );

      if (popup) popup.focus();

      const checkIfClosed = () => {
        if (!popup.closed) return;

        refetch();
        clearInterval(intervalId);
        setLoading(false);

        if (onClose) {
          setTimeout(onClose, 2000);
        }

        if (String(alertMessage).length > 0) {
          setBannerMessage({ type: "info", title: alertMessage });
        }
      };

      const intervalId = setInterval(checkIfClosed, 1000);
    } catch (error) {
      setBannerMessage({
        type: "error",
        title: error.message,
      });
      setLoading(false);
    }
  };

  return { loading, onConnect };
};

export default useOnConnect;
