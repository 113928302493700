import { API_URL } from "../../config";
import { ITEMS_PER_PAGE } from "../store";

export const BLACKLISTED_EXTENSIONS = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "svg",
  "mp4",
  "mov",
  "txt",
];

const getHeaders = (token) => {
  return { Authorization: token };
};

const getOAuthUrl = async ({ chatbotId, dataSource, token, prompt }) => {
  let queryString = `chat_bot_id=${chatbotId}&service=${String(
    dataSource
  ).toLowerCase()}`;

  if (String(prompt).length > 0) {
    queryString += `&confluence_subdomain=${prompt}`;
  }

  const request = await fetch(`${API_URL}/carbon_ai/oauth_url?${queryString}`, {
    method: "POST",
    headers: getHeaders(token),
  });
  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  return response.oauth_url;
};

const getConnections = async ({ chatbotId, dataSource, token }) => {
  const request = await fetch(
    `${API_URL}/carbon_ai/connections?chat_bot_id=${chatbotId}`,
    {
      method: "GET",
      headers: getHeaders(token),
    }
  );
  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  return response.results;
};

const getItems = async ({
  chatbotId,
  dataSourceId,
  token,
  offset = "0",
  parentId,
  signal,
}) => {
  const offsetParam = offset ? `offset=${offset}&` : "";
  const parentIdParams = parentId ? `parent_item_external_id=${parentId}&` : "";

  const request = await fetch(
    `${API_URL}/carbon_ai/items?${offsetParam}${parentIdParams}chat_bot_id=${chatbotId}&limit=${ITEMS_PER_PAGE}&data_source_id=${dataSourceId}&order_by=directories_first&order_dir=desc`,
    {
      method: "GET",
      headers: getHeaders(token),
      signal,
    }
  );
  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  const mappedItems = response.items.map((item) => {
    const hasExtension = item.name.includes(".");

    if (hasExtension) {
      const extension = item.name.split(".").pop();
      return {
        ...item,
        disabled: BLACKLISTED_EXTENSIONS.includes(extension),
      };
    }
    return {
      ...item,
      disabled: item.selected_for_sync,
    };
  });
  return { count: response.count, items: mappedItems };
};

const getContentItems = async ({ chatbotId, token }) => {
  const request = await fetch(
    `${API_URL}/chat_bots/${chatbotId}/content_items`,
    {
      method: "GET",
      headers: getHeaders(token),
    }
  );
  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  return response;
};

const getContentItem = async ({ chatbotId, token, id }) => {
  const request = await fetch(
    `${API_URL}/chat_bots/${chatbotId}/content_items/${id}`,
    {
      method: "GET",
      headers: getHeaders(token),
    }
  );
  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  return response;
};

const deleteRevokeConnection = async ({ chatbotId, dataSourceId, token }) => {
  const request = await fetch(
    `${API_URL}/carbon_ai/revoke_connection?chat_bot_id=${chatbotId}&data_source_id=${dataSourceId}`,
    {
      method: "DELETE",
      headers: getHeaders(token),
    }
  );
  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  return response;
};

const postProcessItems = async ({
  chatbotId,
  dataSourceId,
  token,
  selectedIds,
  deselectedIds,
}) => {
  const selectedIdsParam = selectedIds
    ? `selected_item_external_ids=${selectedIds.join(",")}&`
    : "";
  const deselectedIdsParam = deselectedIds
    ? `deselected_item_external_ids=${deselectedIds.join(",")}&`
    : "";
  const request = await fetch(
    `${API_URL}/carbon_ai/process_items?${selectedIdsParam}${deselectedIdsParam}chat_bot_id=${chatbotId}&data_source_id=${dataSourceId}`,
    {
      method: "POST",
      headers: getHeaders(token),
    }
  );

  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  return response;
};

const putResyncItems = async ({ chatbotId, dataSourceId, token }) => {
  const request = await fetch(
    `${API_URL}/carbon_ai/resync_items?chat_bot_id=${chatbotId}&data_source_id=${dataSourceId}`,
    {
      method: "PUT",
      headers: getHeaders(token),
    }
  );
  const response = await request.json();

  if (response.error) {
    throw new Error(response.message);
  }

  return response;
};

export {
  getOAuthUrl,
  getConnections,
  getContentItems,
  getContentItem,
  getItems,
  deleteRevokeConnection,
  postProcessItems,
  putResyncItems,
};
