import { ReactComponent as ViewAction } from "../../../media/viewAction.svg";
import { ReactComponent as ReloadAction } from "../../../media/reloadAction.svg";
import { ReactComponent as CloseAction } from "../../../media/closeAction.svg";
import { ReactComponent as DownloadAction } from "../../../media/downloadAction.svg";
import { API_URL } from "../../../config";
import { useContext, useState } from "react";
import { externalSources, KnowledgeBaseContext } from "../KnowledgeBase";
import "./Actions.css";
import { waitForIndexingToFinish } from "../indexing";
import { useConfirm } from "../../../Tools/Confirm/Confirm";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { downloadContentItemDocument } from "./downloadContentItemDocument";
import { postProcessItems } from "../../../ExternalSources/http";

export const Actions = ({
  source,
  setModalOpen,
  setFetchingDeleteResponse,
  deleteFunctionDisabled = false,
  viewActionDisabled = false,
  reindexActionDisabled = false,
}) => {
  const {
    chatbotId,
    token,
    sourceType,
    setRawSources,
    setWebSources,
    setPdfSources,
    setTextSources,
    setOpenIntervals,
    setDeletingSources,
  } = useContext(KnowledgeBaseContext);
  const { fetchUserInfo } = useContext(WrappedComponentContext);

  const [canRemove, setCanRemove] = useState(true);
  const [canReindex, setCanReindex] = useState(true);

  const confirm = useConfirm();

  const removeSource = async (source) => {
    try {
      if (deleteFunctionDisabled) return;
      if (!canRemove) return;

      const confirmed = await confirm({
        title: "Delete source",
        description: "Are you sure you want to delete this source?",
      });
      if (!confirmed) return;

      setFetchingDeleteResponse(true);
      setCanRemove(false);

      if (!!source.carbon_item_type) {
        await postProcessItems({
          chatbotId,
          token,
          source,
          dataSourceId: source.content.organization_user_data_source_id,
          deselectedIds: [source.id],
        });
      }

      const response = await fetch(
        `${API_URL}/chat_bots/${chatbotId}/content_items/${source.id}`,
        {
          method: "DELETE",
          headers: { Authorization: token },
        }
      );
      if (!response.ok) {
        if (response.status !== 404) {
          throw `Could not remove source ${source?.title} ${source?.id}`;
        }
      }
      setCanRemove(true);

      let setSources = setWebSources;
      if (sourceType === "Pdf") setSources = setPdfSources;
      else if (sourceType === "Text") setSources = setTextSources;
      setSources((prevSources) =>
        prevSources.filter((item) => item.id !== source.id)
      );
      setRawSources((prevSources) =>
        prevSources.filter((item) => item.id !== source.id)
      );

      // Add to deletion list to ping for updates
      if (sourceType === "Web") {
        setDeletingSources((prev) => [
          ...prev,
          { ...source, pendingDeletion: true },
        ]);
      } else {
        fetchUserInfo();
      }
    } catch (error) {
      setCanRemove(true);
      setFetchingDeleteResponse(false);
      console.error(error);
    }
  };

  const reindex = async () => {
    try {
      if (!canReindex || reindexActionDisabled) return;

      const confirmed = await confirm({
        title: "Reindex source",
        description: `Are you sure you want to reindex ${source?.title}?`,
      });
      if (!confirmed) return;

      setCanReindex(false);

      const response = await fetch(
        `${API_URL}/chat_bots/${source?.chat_bot_id}/content_items/${source?.id}/perform_gather_data?perform_index=true`,
        {
          method: "PUT",
          headers: { Authorization: token },
        }
      );
      if (!response.ok)
        throw `Could not reindex source ${source?.title} ${source?.id}`;
      setCanReindex(true);

      // const reindexedContentItem = await response.json();
      let setSources = setWebSources;
      if (sourceType === "Pdf") setSources = setPdfSources;
      else if (sourceType === "Text") setSources = setTextSources;

      waitForIndexingToFinish({
        chatbotId,
        token,
        contentItemId: source?.id,
        setOpenIntervals,
        setSources,
        fetchUserInfo,
      });
    } catch (err) {
      setCanReindex(true);
      console.error(err);
    }
  };

  const isDownload =
    ["Pdf", ...externalSources].includes(sourceType) && !!source?.document_url;

  const ViewActionIcon = ["Pdf", ...externalSources].includes(sourceType)
    ? DownloadAction
    : ViewAction;

  return (
    <div className="row-space-between" style={{ gap: "5px" }}>
      {/* link is for downloading file when type is a document */}

      <div
        className={`action-icon-container ${
          viewActionDisabled ? "disabled" : ""
        }`}
      >
        <ViewActionIcon
          onClick={() => {
            if (!viewActionDisabled)
              isDownload
                ? downloadContentItemDocument(
                    source?.document_url,
                    source?.title,
                    token
                  )
                : setModalOpen(true);
          }}
        />
        <span
          className="action-icon-text"
          style={
            isDownload
              ? { width: "149px", right: "-10px" }
              : { width: "85px", right: "-10px" }
          }
        >
          {isDownload ? "Download original file" : "More details"}
        </span>
      </div>

      {!["Pdf", "Text", ...externalSources].includes(sourceType) && (
        <div
          className={`action-icon-container ${
            reindexActionDisabled ? "disabled" : ""
          }`}
        >
          <ReloadAction onClick={reindex} />
          <span
            className="action-icon-text"
            style={{ width: "183px", right: "-10px" }}
          >
            Refresh and index a new version of this data source
          </span>
        </div>
      )}

      <div
        className={`action-icon-container ${
          deleteFunctionDisabled ? "disabled" : ""
        }`}
      >
        <CloseAction onClick={() => removeSource(source)} />
        <span
          className="action-icon-text"
          style={{ width: "160px", right: "-10px" }}
        >
          Delete this data source from the chatbot
        </span>
      </div>
    </div>
  );
};
