import { useContext, useEffect } from "react";
import {
  REFETCH_INTERVAL_MS,
  useBoxStore,
  useConfluenceStore,
  useDropboxStore,
  useGoogleDriveStore,
  useNotionStore,
} from "../store";
import useFetchConnection from "./useFetchConnection";
import useOnConnect from "./useOnConnect";
import useOnResync from "./useOnResync";
import useOnDisconnect from "./useOnDisconnect";
import { getContentItem, getContentItems, postProcessItems } from "../http";
import { WrappedComponentContext } from "../../WrappedComponent";

const useExternalSource = ({
  token,
  chatbotId,
  dataSource,
  rawSources,
  setRawSources,
  fetchContentItems,
}) => {
  const { setBannerMessage } = useContext(WrappedComponentContext);
  const stores = {
    DROPBOX: useDropboxStore,
    BOX: useBoxStore,
    CONFLUENCE: useConfluenceStore,
    GOOGLE_DRIVE: useGoogleDriveStore,
    NOTION: useNotionStore,
  };
  const store = stores[dataSource];

  if (!store) {
    throw new Error("This data-source is not included in the stores list.");
  }

  const {
    isQueuedForSyncing,
    checkedFiles,
    email,
    isConnected,
    isModalVisible,
    setDataSourceId,
    showModal,
    connect,
    hideModal,
    setIsLoadingFiles,
    clearCheckedFiles,
    setReviewMode,
    setIsQueuedForSyncing,
  } = store();

  const {
    isRefetching,
    loading: fetchConnectionLoading,
    connection,
    refetch,
  } = useFetchConnection({
    token,
    chatbotId,
    dataSource,
    connect,
  });

  const { loading: connectLoading, onConnect } = useOnConnect({
    chatbotId,
    dataSource,
    store,
    token,
    refetch,
  });

  const { loading: resyncLoading, onResync } = useOnResync({
    chatbotId,
    dataSourceId: connection?.id,
    store,
    token,
    refetch,
  });

  const { loading: disconnectLoading, onDisconnect } = useOnDisconnect({
    chatbotId,
    dataSourceId: connection?.id,
    store,
    token,
  });

  const items = rawSources.filter(
    (source) => source.carbon_item_type === dataSource
  );

  const onComponentRender = () => {
    if (!connection) return;

    if (!connection.data_source_external_id) return;

    const newAccount = connection.data_source_external_id.includes("|")
      ? connection.data_source_external_id.split(`|`)[1]
      : connection.data_source_external_id.split(`-`)[1];
    connect(newAccount);
    setDataSourceId(connection.id);
  };

  useEffect(onComponentRender, [connection, connect, setDataSourceId]);

  useEffect(() => {
    if (!connection) return;

    setIsQueuedForSyncing(
      ["QUEUED_FOR_SYNCING", "SYNCING"].includes(connection.sync_status)
    );
  }, [connection, setIsQueuedForSyncing]);

  useEffect(() => {
    if (!connection) return;

    let intervalId;

    if (isQueuedForSyncing) {
      intervalId = setInterval(() => {
        refetch();

        if (connection.sync_status === "READY") {
          clearInterval(intervalId);
        }
      }, REFETCH_INTERVAL_MS);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isQueuedForSyncing, refetch, connection]);

  const onSelectFiles = async () => {
    if (dataSource === "NOTION") {
      await onConnect({
        alertMessage: "Notion pages may take a few minutes to sync.",
        onClose: () => {
          fetchContentItems();
        },
      });
      return;
    }

    showModal();
  };

  const isLoading =
    disconnectLoading ||
    connectLoading ||
    fetchConnectionLoading ||
    resyncLoading;

  const dataSourceId = connection?.id ?? "";

  const onAddFiles = async () => {
    const selectedIds = checkedFiles.map((file) => file.external_id);
    setIsLoadingFiles(true);
    try {
      await postProcessItems({
        chatbotId,
        dataSourceId,
        token,
        selectedIds,
      });
    } catch (e) {
      setBannerMessage({ type: "error", title: e.message });
      setReviewMode(false);
      setIsLoadingFiles(false);
      clearCheckedFiles();
      hideModal();
      return;
    }
    const contentItems = await getContentItems({ chatbotId, token });
    const newRawSources = [
      ...rawSources,
      ...checkedFiles.map((file) => {
        const id = contentItems.find((v) => v.title === file.name)?.id;

        return {
          carbon_item_type: dataSource,
          date: new Date(),
          state: "gathering_data",
          title: file.name,
          "can_destroy?": true,
          id,
          content: {
            organization_user_data_source_id: dataSourceId,
          },
        };
      }),
    ];
    setRawSources(newRawSources);

    const addedContentItemIds = newRawSources
      .filter(
        (file) =>
          file.carbon_item_type === dataSource &&
          file.state === "gathering_data"
      )
      .map((file) => file.id);

    const intervals = [];

    const loadContentItem = async ({ chatbotId, token, id }) => {
      if (!id) return;

      const response = await getContentItem({ chatbotId, token, id });

      if (response.state === "indexed") {
        const intervalIds = intervals
          .filter((i) => i.id === id)
          .map((i) => i.intervalID);

        intervalIds.forEach((i) => clearInterval(i));
        setRawSources(
          [...newRawSources].map((item) => {
            if (item.id === id) {
              item.state = "indexed";
            }
            return item;
          })
        );
      } else {
        const intervalID = setTimeout(async () => {
          await loadContentItem({ chatbotId, token, id });
        }, REFETCH_INTERVAL_MS);
        intervals.push({ id, intervalID });
      }
    };

    addedContentItemIds.forEach((id) => {
      loadContentItem({ chatbotId, token, id });
    });

    setReviewMode(false);
    setIsLoadingFiles(false);
    clearCheckedFiles();
    hideModal();
  };

  return {
    email,
    dataSourceId,
    checkedFiles,
    isLoading,
    isRefetching,
    isConnected,
    isModalVisible,
    isQueuedForSyncing,
    items,
    store,
    onDisconnect,
    onConnect,
    onSelectFiles,
    onAddFiles,
    onResync,
    onRefetch: refetch,
  };
};

export default useExternalSource;
