import { useEffect } from "react";
import ReviewScreen from "./ReviewScreen";
import SelectScreen from "./SelectScreen";
import Modal from "../../../Modal/Modal";
import ActionButtons from "./ActionButtons";
import { getItems } from "../../http";
import { ITEMS_PER_PAGE } from "../../store";

const SelectFilesModal = ({
  chatbotId,
  dataSource,
  token,
  store,
  onAddFiles,
}) => {
  const {
    addToBreadcrumb,
    appendToBreadcrumb,
    currentBreadcrumb,
    dataSourceId,
    hideModal,
    isModalVisible,
    offset,
    parentId,
    isReviewMode,
    setIsLoadingFiles,
    setOffset,
    setParentId,
  } = store();

  const onFolder = async (folder) => {
    setIsLoadingFiles(true);
    setOffset(0);
    setParentId(folder.external_id);
    const { items, count } = await getItems({
      chatbotId,
      dataSourceId,
      token,
      parentId: folder.external_id,
      offset: 0,
    });
    setIsLoadingFiles(false);

    const isFirstPage = offset === 0;
    const hasMoreItems = count > offset;

    if (hasMoreItems && !isFirstPage) {
      setOffset(offset + ITEMS_PER_PAGE);
    }

    addToBreadcrumb(folder.name, items, count);
  };

  const onBottomScroll = async (signal) => {
    try {
      if (offset === 0) return;

      setIsLoadingFiles(true);
      const { items, count } = await getItems({
        chatbotId,
        dataSourceId,
        token,
        offset,
        parentId,
        signal,
      });
      const newOffset = offset + ITEMS_PER_PAGE;
      const hasMoreItems = newOffset < count;

      if (hasMoreItems) {
        setOffset(newOffset);
      } else {
        setOffset(0);
      }

      appendToBreadcrumb(items, currentBreadcrumb);
    } catch (e) {
      console.log("Error while fetching data.");
    }
    setIsLoadingFiles(false);
  };

  const onFetchData = () => {
    if (!isModalVisible || !dataSourceId) return;

    const fetchData = async () => {
      setIsLoadingFiles(true);
      const { items, count } = await getItems({
        chatbotId,
        dataSourceId,
        token,
      });

      if (count > ITEMS_PER_PAGE) {
        setOffset(ITEMS_PER_PAGE);
      }

      const names = {
        GOOGLE_DRIVE: "Google Drive",
        DROPBOX: "Dropbox",
        BOX: "Box",
        CONFLUENCE: "Confluence",
        NOTION: "Notion",
      };

      if (!(dataSource in names)) {
        throw new Error("The network name is not present on names list.");
      }

      addToBreadcrumb(names[dataSource], items, count);
      setIsLoadingFiles(false);
    };

    fetchData();
  };

  useEffect(onFetchData, [
    addToBreadcrumb,
    chatbotId,
    dataSource,
    dataSourceId,
    isModalVisible,
    setIsLoadingFiles,
    setOffset,
    token,
  ]);

  if (!isModalVisible) return null;

  return (
    <Modal
      setOpen={hideModal}
      component={
        <div style={{ position: "relative" }}>
          {isReviewMode && (
            <ReviewScreen dataSource={dataSource} store={store} />
          )}
          {!isReviewMode && (
            <SelectScreen
              dataSource={dataSource}
              store={store}
              onBottomScroll={onBottomScroll}
              onFolder={onFolder}
            />
          )}
          <ActionButtons store={store} onAddFiles={onAddFiles} />
        </div>
      }
      extraClassName="select-files-modal"
      closeOnOutsideClick={true}
    />
  );
};

export default SelectFilesModal;
