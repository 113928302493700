import { useContext, useEffect, useState } from "react";
import "./ManageChatbot.css";
import KnowledgeBase from "./KnowledgeBase/KnowledgeBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import chatbotIcon from "../media/chatbotPurple.svg";
import { API_URL } from "../config";
import Stats from "./Stats/Stats";

import { ReactComponent as StatsIcon } from "../media/websiteClear.svg";
import { ReactComponent as KnowledgeBaseIcon } from "../media/bookClear.svg";
import { ReactComponent as ConversationsIcon } from "../media/conversationsClear.svg";
import { ReactComponent as ChatIcon } from "../media/chatbotClear.svg";
import { ReactComponent as SettingsIcon } from "../media/settings.svg";
import Conversations from "./Conversations/Conversations";
import NoMatch from "../NoMatch/NoMatch";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../WrappedComponent";
import DisabledIndication from "../Tools/DisabledIndication/DisabledIndication";
import HostedChatbot from "../HostedChatbot/HostedChatbot";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";

const validTabs = [
  "stats",
  "knowledgebase",
  "conversations",
  "chat",
  "file-picker",
];

export default function ManageChatbot() {
  const { chatbotId, tabParam } = useParams();

  const { logout, userInfo, fetchUserInfo, token } = useContext(
    WrappedComponentContext
  );
  const navigate = useNavigate();

  const [chatbot, setChatbot] = useState();
  const [tab, setTab] = useState(tabParam ? tabParam : "knowledgebase");
  const [errorFindingChatbot, setErrorFindingChatbot] = useState(false);
  const [incorrectTab, setIncorrectTab] = useState(false);

  useEffect(() => {
    if (!userInfo && !["stats", "knowledgebase"].includes(tab)) fetchUserInfo();

    if (tab !== "stats") fetchChatbot();

    document.title = "Chatbot | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  // If there is no subroute we redirect to /stats
  useEffect(() => {
    if (!tabParam) navigate(`/chatbot/${chatbotId}/knowledgebase`);
  }, [tabParam]);

  useEffect(() => {
    if (tab && !validTabs.includes(tab)) setIncorrectTab(true);

    if (tab === "stats") fetchChatbot();
  }, [tab]);

  const fetchChatbot = async () => {
    try {
      const endpoint = API_URL + "/chat_bots/" + chatbotId;
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      const response = await fetch(endpoint, { headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to fetch chatbot.",
          response.status,
          logout
        );

      const data = await response.json();
      setChatbot(data);
    } catch (err) {
      setErrorFindingChatbot(true);
      console.error(err?.errorMessage ?? err);
    }
  };

  const switchTab = (tab) => {
    setTab(tab);
  };

  if (errorFindingChatbot) navigate("/");
  if (!token) navigate("/login");
  if (incorrectTab) return <NoMatch />;

  return (
    <div className="page-card-container">
      <div className="page-card">
        <Link to="/chatbots" className="manage-chatbot-back-to-chatbots">
          {"<  Back to Chatbots"}
        </Link>

        {chatbot ? (
          <>
            <div className="page-card-title-container">
              <div className="manage-chatbot-title">
                {chatbot?.avatar_url ? (
                  <img
                    src={chatbot?.avatar_url}
                    height="100%"
                    alt="Chatbot Icon"
                  />
                ) : (
                  <img src={chatbotIcon} height="100%" alt="Chatbot Icon" />
                )}

                <h1 className="title-20">{chatbot?.name}</h1>
              </div>

              <div className="flex row-align-center" style={{ gap: "10px" }}>
                {(chatbot?.admin_disabled || chatbot?.user_disabled) && (
                  <DisabledIndication overflowRisk="right" />
                )}

                <Link
                  to={`/chatbot/settings/${chatbotId}`}
                  style={{ position: "relative" }}
                  className="chatbot-settings-icon"
                >
                  <div className="chatbot-settings-icon-hover">
                    Chatbot Settings
                  </div>

                  <SettingsIcon
                    style={{ cursor: "pointer", display: "flex" }}
                  />
                </Link>
              </div>
            </div>
            <br />
            <br />

            <div
              className="manage-chatbot-menu-container"
              style={{ overflowX: "auto" }}
            >
              <Link
                to={`/chatbot/${chatbotId}/stats`}
                className={`new-chatbot-tab-title manage-chatbot-tab ${
                  tab === "stats" ? "selected" : ""
                }`}
                onClick={() => switchTab("stats")}
              >
                <StatsIcon
                  className={`menu-icon ${tab === "stats" ? "selected" : ""}`}
                />
                <span className="manage-chatbot-menu-item-text">STATS</span>
              </Link>
              <Link
                to={`/chatbot/${chatbotId}/knowledgebase`}
                className={`new-chatbot-tab-title manage-chatbot-tab ${
                  tab === "knowledgebase" ? "selected" : ""
                }`}
                onClick={() => switchTab("knowledgebase")}
              >
                <KnowledgeBaseIcon
                  className={`menu-icon ${
                    tab === "knowledgebase" ? "selected" : ""
                  }`}
                  style={{ marginRight: "4px" }}
                />
                <span className="manage-chatbot-menu-item-text">
                  KNOWLEDGE BASE
                </span>
              </Link>

              <Link
                to={`/chatbot/${chatbotId}/conversations`}
                className={`new-chatbot-tab-title manage-chatbot-tab ${
                  tab === "conversations" ? "selected" : ""
                }`}
                onClick={() => switchTab("conversations")}
              >
                <ConversationsIcon
                  className={`menu-icon ${
                    tab === "conversations" ? "selected" : ""
                  }`}
                />
                <span className="manage-chatbot-menu-item-text">
                  CONVERSATIONS
                </span>
              </Link>

              <Link
                to={`/chatbot/${chatbotId}/chat`}
                className={`new-chatbot-tab-title manage-chatbot-tab ${
                  tab === "chat" ? "selected" : ""
                }`}
                onClick={() => switchTab("chat")}
              >
                <ChatIcon
                  className={`menu-icon ${tab === "chat" ? "selected" : ""}`}
                />
                <span className="manage-chatbot-menu-item-text">CHAT</span>
              </Link>
            </div>

            <div className="page-card-divider no-margin-top" />

            {tab === "stats" && <Stats chatbot={chatbot} />}

            {tab === "chat" && (
              <HostedChatbot
                fullspace={true}
                chatbotParam={chatbot}
                isAdmin={true}
              />
            )}

            {tab === "knowledgebase" && (
              <KnowledgeBase
                chatbotId={chatbotId}
                chatbot={chatbot}
                setChatbot={setChatbot}
                userInfo={userInfo}
              />
            )}

            {tab === "conversations" && (
              <Conversations
                chatbotId={chatbotId}
                chatbot={chatbot}
                token={token}
              />
            )}
          </>
        ) : (
          <WaitingWithText>Loading Chatbot</WaitingWithText>
        )}
      </div>
    </div>
  );
}
