import { useQuery } from "@tanstack/react-query";
import { getConnections } from "../../http";

const useConnectionsQuery = (params) => {
  const { chatbotId } = params;
  const { data, error, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["connections", chatbotId],
    queryFn: () => getConnections(params),
    refetchOnWindowFocus: true,
    refetchOnMount: false,
  });

  return { data, error, loading: isLoading, isRefetching, refetch };
};

export default useConnectionsQuery;
