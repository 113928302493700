import addPurple from "../../../media/addPurple.svg";
import loadingPurple from "../../../media/loadingPurple.svg";
import useExternalSource from "../../../ExternalSources/hooks/useExternalSource";
import SelectFilesModal from "../../../ExternalSources/components/SelectFilesModal/SelectFilesModal";
import { KnowledgeBaseTableRow } from "../../../ManageChatbot/KnowledgeBase/KnowledgeBaseTableRow/KnowledgeBaseTableRow";
import { DATA_SOURCES_WITH_PAGES } from "../../store";
import CircularIndeterminate from "../../../Tools/Loading/CircularIndeterminate";
import Skeleton from "react-loading-skeleton";
import CircularLoader from "../CircularLoader/CircularLoader";

const ExternalSource = ({
  dataSource = "",
  dataSourceLabel = "",
  rawSources,
  token,
  chatbotId,
  fetchContentItems,
  fetchContentItemsLoading,
  setRawSources,
}) => {
  const {
    email,
    isQueuedForSyncing,
    isConnected,
    isLoading,
    items,
    store,
    onConnect,
    onResync,
    onDisconnect,
    onSelectFiles,
    onAddFiles,
  } = useExternalSource({
    token,
    chatbotId,
    dataSource,
    rawSources,
    setRawSources,
    fetchContentItems,
  });

  const hasPagesInsteadOfFiles = DATA_SOURCES_WITH_PAGES.includes(dataSource);

  return (
    <div className="sources" style={{ position: "relative" }}>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {isConnected && !isQueuedForSyncing && (
          <div style={{ display: "inline-block" }}>
            <button
              className={`add-source ${isLoading ? "loading" : ""}`}
              onClick={onSelectFiles}
            >
              <img
                src={addPurple}
                alt={`Select ${hasPagesInsteadOfFiles ? "Pages" : "Files"}`}
              />
              <span>Select {hasPagesInsteadOfFiles ? "Pages" : "Files"}</span>
            </button>
          </div>
        )}
        {!isConnected && (
          <div style={{ display: "inline-block" }}>
            <button
              className={`add-source ${isLoading ? "loading" : ""}`}
              onClick={onConnect}
            >
              <img src={addPurple} alt={`Connect ${dataSourceLabel} Account`} />
              <span>{`Connect ${dataSourceLabel} Account`}</span>
            </button>
          </div>
        )}
        {isConnected && (
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            {!isLoading && !isQueuedForSyncing && (
              <button
                className={`add-source ${isLoading ? "loading" : ""}`}
                onClick={onResync}
              >
                {`Resync`}
              </button>
            )}
            {isQueuedForSyncing && (
              <span
                style={{
                  display: "inline-flex",
                  gap: "12px",
                  fontSize: "12px",
                  alignItems: "center",
                }}
              >
                <CircularIndeterminate />
                Still syncing, please check back in a few minutes...
              </span>
            )}
          </div>
        )}
      </div>

      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="kb-title">Current {dataSourceLabel} Sources</span>{" "}
          <CircularLoader refetch={fetchContentItems} />
        </div>

        <table className="scrollable-table knowledge-base-table">
          <thead className="kb-tr">
            <tr className="kb-tr">
              <th className="kb-side-table-item">
                <div className="row-align-center first-column-styles">
                  <img src={loadingPurple} style={{ visibility: "hidden" }} />
                  <span
                    className="table-icon-next-to-text"
                    style={{ marginLeft: "8px" }}
                  >
                    {hasPagesInsteadOfFiles ? "Page Title" : "Document Title"}
                  </span>
                </div>
              </th>
              <th className="kb-table-item date">Index date</th>
              <th className={`kb-table-item actions`}>Actions</th>
            </tr>
          </thead>
          <tbody className="kb-tr" style={{ display: "block" }}>
            {items.map((item) => (
              <KnowledgeBaseTableRow
                source={item}
                key={item.id}
                showType={false}
              />
            ))}
          </tbody>
          {(isLoading || fetchContentItemsLoading) && (
            <tfoot
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <tr className="kb-tr">
                <td className="kb-side-table-item">
                  <div className="row-align-center first-column-styles">
                    <div style={{ width: "100%", marginRight: "9px" }}>
                      <Skeleton />
                    </div>
                  </div>
                </td>
                <td className="kb-table-item date">
                  <div style={{ width: "100%" }}>
                    <Skeleton />
                  </div>
                </td>
                <td className="kb-table-item actions">
                  <div style={{ width: "100%" }}>
                    <Skeleton />
                  </div>
                </td>
              </tr>
              <tr className="kb-tr">
                <td className="kb-side-table-item">
                  <div className="row-align-center first-column-styles">
                    <div style={{ width: "100%", marginRight: "9px" }}>
                      <Skeleton />
                    </div>
                  </div>
                </td>
                <td className="kb-table-item date">
                  <div style={{ width: "100%" }}>
                    <Skeleton />
                  </div>
                </td>
                <td className="kb-table-item actions">
                  <div style={{ width: "100%" }}>
                    <Skeleton />
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </>

      <SelectFilesModal
        store={store}
        chatbotId={chatbotId}
        dataSource={dataSource}
        token={token}
        onAddFiles={onAddFiles}
      />

      {String(email).length > 0 && (
        <p style={{ fontSize: "14px", color: "#1E1E1E" }}>
          {dataSourceLabel} connected: {email} (
          <button
            onClick={onDisconnect}
            style={{
              color: "#7E00E2",
              background: "transparent",
              border: "none",
              padding: 0,
            }}
          >
            Disconnect
          </button>
          )
        </p>
      )}
    </div>
  );
};

export default ExternalSource;
