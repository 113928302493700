import React from "react";
// import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "react-loading-skeleton/dist/skeleton.css";
import App from "./App";
import { hydrate, render } from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const rootElement = document.getElementById("root");

const AppWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

if (rootElement.hasChildNodes()) {
  hydrate(<AppWithProviders />, rootElement);
} else {
  render(<AppWithProviders />, rootElement);
}
