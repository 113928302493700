import CircularIndeterminate from "../../../Tools/Loading/CircularIndeterminate";

const Loader = ({ height = "100%" }) => {
  return (
    <div>
      <CircularIndeterminate />
    </div>
  );
};

export default Loader;
