// import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";

const CircularLoader = ({ initialTime = 30, refetch }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [progress, setProgress] = useState(100);
  const intervalIdRef = useRef(null); // Use a ref to keep track of the interval ID

  useEffect(() => {
    const startCountdown = () => {
      clearInterval(intervalIdRef.current); // Clear any existing interval
      intervalIdRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            refetch();
            return initialTime;
          }
          return prevTime - 1;
        });
      }, 1000);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Reset the countdown and start it
        setTimeLeft(initialTime);
        startCountdown();
      } else {
        // Pause the countdown
        clearInterval(intervalIdRef.current);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Start the countdown initially
    startCountdown();

    return () => {
      clearInterval(intervalIdRef.current);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [initialTime, refetch]);

  useEffect(() => {
    setProgress((timeLeft / initialTime) * 100);
  }, [timeLeft, initialTime]);

  return null;
  // return (
  //   <Box
  //     title="Time left before next refresh"
  //     sx={{
  //       position: "relative",
  //       display: "inline-flex",
  //       opacity: 0.5,
  //       marginLeft: "auto",
  //     }}
  //   >
  //     <CircularProgress
  //       sx={{ color: "#1e1e1e" }}
  //       variant="determinate"
  //       size="24px"
  //       value={progress}
  //     />
  //     <Box
  //       sx={{
  //         top: 0,
  //         left: 0,
  //         bottom: 0,
  //         right: 0,
  //         position: "absolute",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Typography
  //         variant="caption"
  //         component="div"
  //         sx={{ color: "#1e1e1e", fontSize: "8px" }}
  //       >
  //         {`${timeLeft}s`}
  //       </Typography>
  //     </Box>
  //   </Box>
  // );
};

export default CircularLoader;
