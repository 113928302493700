import { DATA_SOURCES_WITH_PAGES } from "../../store";

const ActionButtons = ({ dataSource, store, onAddFiles }) => {
  const hasPagesInsteadOfFiles = DATA_SOURCES_WITH_PAGES.includes(dataSource);
  const { isReviewMode, setReviewMode, hideModal } = store();

  const onContinue = () => {
    setReviewMode(true);
  };

  const onBack = () => {
    setReviewMode(false);
  };

  const onCancel = () => {
    hideModal();
  };
  return (
    <div style={{ marginTop: "24px" }}>
      <button
        onClick={isReviewMode ? onAddFiles : onContinue}
        style={{
          background: "#A24DFF",
          height: "40px",
          paddingLeft: "16px",
          paddingRight: "16px",
          border: "none",
          borderRadius: "10px",
          color: "#FFF",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        {isReviewMode
          ? `Add ${hasPagesInsteadOfFiles ? "Pages" : "Files"}`
          : "Continue"}
      </button>
      <button
        onClick={isReviewMode ? onBack : onCancel}
        style={{
          background: "#FFF",
          height: "40px",
          border: "1px solid #EBEBEC",
          borderRadius: "10px",
          marginLeft: "12px",
          paddingLeft: "16px",
          paddingRight: "16px",
          color: "#5F5968",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        {isReviewMode ? "Back" : "Cancel"}
      </button>
    </div>
  );
};

export default ActionButtons;
