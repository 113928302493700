import { createContext, useContext, useEffect, useRef, useState } from "react";
import "./KnowledgeBase.css";
import loadingPurple from "../../media/loadingPurple.svg";
import { API_URL } from "../../config";
import selectedWebsiteIcon from "../../media/websiteIcon.svg";
import websiteIcon from "../../media/websiteIconGrey.svg";
import selectedPdfIcon from "../../media/pdfIcon.svg";
import pdfIcon from "../../media/pdfIconGrey.svg";
import selectedTextIcon from "../../media/textIcon.svg";
import textIcon from "../../media/textIconGrey.svg";
import startersIcon from "../../media/startersIcon.svg";
import selectedStartersIcon from "../../media/selectedStartersIcon.svg";
import dropboxIcon from "../../media/dropboxIcon.svg";
import googleDriveIcon from "../../media/googleDriveIcon.svg";
import boxIcon from "../../media/boxIcon.svg";
import notionIcon from "../../media/notionIcon.svg";
import confluenceIcon from "../../media/confluenceIcon.svg";
import { extractSources } from "./extractSources";
import { waitForIndexingForLoadingSources } from "./indexing";
import { AddSourceButton } from "./AddSources/AddSourceButton";
import { KnowledgeBaseTableRow } from "./KnowledgeBaseTableRow/KnowledgeBaseTableRow";
import WaitingWithText from "../../Tools/ChatWaiting/WaitingWithText";
import KBStarters from "./KBStarters";
import { updatePendingDeletionStatus } from "./updatePendingDeletionStatus";
import { Link } from "react-router-dom";
import { EventTrigger } from "../../Tools/EventTriggers/EventTriggers";
import { WrappedComponentContext } from "../../WrappedComponent";
import ExternalSource from "../../ExternalSources/components/ExternalSource/ExternalSource";
import { useQuery } from "@tanstack/react-query";

export const externalSources = [
  "Google Drive",
  "Box",
  "Confluence",
  "Dropbox",
  "Notion",
];

export const KnowledgeBaseContext = createContext();

export default function KnowledgeBase({
  chatbotId,
  chatbot,
  setChatbot,
  userInfo,
}) {
  const { token, fetchUserInfo } = useContext(WrappedComponentContext);

  const [sourceType, setSourceType] = useState("Web");
  const [rawSources, setRawSources] = useState([]);
  const [webSources, setWebSources] = useState([]);
  const [pdfSources, setPdfSources] = useState([]);
  const [textSources, setTextSources] = useState([]);
  const [currentSources, setCurrentSources] = useState([]);
  const [areSourcesFetched, setAreSourcesFetched] = useState(false);
  const planCharLimitExceeded =
    userInfo?.plan_information?.plan_character_index_limit_within_plan ===
    false;

  const initialSourceCountRef = useRef("not set");
  const hasRunGtagRef = useRef(false);

  const [tabActive, setTabActive] = useState(true);
  const [hasSwitchedTab, setHasSwitchedTab] = useState(false);

  const [deletingSources, setDeletingSources] = useState([]);
  const pendingDeletionIsPingingRef = useRef(false);

  // Each of these states indicates whether we need to wait for indexing to finish for
  // sources that are loading - used when extracting existing sources
  const [pingWebSourcesLoaded, setPingWebSourcesLoaded] = useState(false);
  const [pingPdfSourcesLoaded, setPingPdfSourcesLoaded] = useState(false);
  const [pingTextSourcesLoaded, setPingTextSourcesLoaded] = useState(false);
  const [pingRawSourcesLoaded, setPingRawSourcesLoaded] = useState(false);

  const fetchContentItems = () => {
    const endpoint = `${API_URL}/chat_bots/${chatbotId}/content_items`;
    const headers = { Authorization: token };

    return fetch(endpoint, { headers })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch content items");
        return response.json();
      })
      .then((data) => {
        if (initialSourceCountRef.current === "not set")
          initialSourceCountRef.current = data.length;
        setAreSourcesFetched(true);
        extractSources({
          sources: data,
          setRawSources,
          setWebSources,
          setPdfSources,
          setTextSources,
          setPingWebSourcesLoaded,
          setPingPdfSourcesLoaded,
          setPingTextSourcesLoaded,
          setPingRawSourcesLoaded,
        });
        return data;
      })
      .catch((error) => console.error(error));
  };

  const { refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["chatbot", chatbotId, "contentItems"],
    queryFn: fetchContentItems,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
  });

  // To keep track of open intervals - to clean up when exiting page
  // const [openIntervals, setOpenIntervals] = useState([]);
  const openIntervals = useRef([]);
  const setOpenIntervals = (cb) => {
    openIntervals.current = cb(openIntervals.current);
  };

  const sourcesToList =
    sourceType === "Web"
      ? [...deletingSources, ...currentSources]
      : currentSources;

  useEffect(() => {
    // Load user info
    fetchUserInfo();

    // Gets content items pending deletion
    const fetchDeletingItems = () => {
      const endpoint = `${API_URL}/chat_bots/${chatbotId}/pending_content_item_deletions`;
      const headers = { Authorization: token };

      fetch(endpoint, { headers })
        .then((response) => {
          if (!response.ok) throw new Error("Failed to fetch deleting sources");
          return response.json();
        })
        .then((data) => {
          data = data.map((item) => ({ ...item, pendingDeletion: true }));
          setAreSourcesFetched(true);
          setDeletingSources(data);
        })
        .catch((error) => console.error(error?.message));
    };
    fetchDeletingItems();

    // Event listener for tab changing
    const handleVisibilityChange = () => {
      setTabActive(document.visibilityState === "visible");
      setHasSwitchedTab(true);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // cleans up all the open intervals
      closeOpenIntervals();

      // Remove tab change event listener
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Updates current sources to be the sources of the current open source tab
  useEffect(() => {
    if (sourceType === "Web") setCurrentSources(webSources);
    else if (sourceType === "Pdf") setCurrentSources(pdfSources);
    else setCurrentSources(textSources);
  }, [sourceType, webSources, pdfSources, textSources]);

  // These are for when we want to start the intervals for checking if needs_index
  // at any time that is not while creating the source
  useEffect(() => {
    waitForIndexingForLoadingSources({
      type: "web",
      sources: webSources,
      setSources: setWebSources,
      pingSourcesLoaded: pingWebSourcesLoaded,
      setPingSourcesLoaded: setPingWebSourcesLoaded,
      chatbotId,
      token,
      setOpenIntervals,
      fetchUserInfo,
    });
  }, [webSources, pingWebSourcesLoaded]);

  useEffect(() => {
    waitForIndexingForLoadingSources({
      type: "pdf",
      sources: pdfSources,
      setSources: setPdfSources,
      pingSourcesLoaded: pingPdfSourcesLoaded,
      setPingSourcesLoaded: setPingPdfSourcesLoaded,
      chatbotId,
      token,
      setOpenIntervals,
      fetchUserInfo,
    });
  }, [pdfSources, pingPdfSourcesLoaded]);

  useEffect(() => {
    waitForIndexingForLoadingSources({
      type: "text",
      sources: textSources,
      setSources: setTextSources,
      pingSourcesLoaded: pingTextSourcesLoaded,
      setPingSourcesLoaded: setPingTextSourcesLoaded,
      chatbotId,
      token,
      setOpenIntervals,
      fetchUserInfo,
    });
  }, [textSources, pingTextSourcesLoaded]);

  useEffect(() => {
    waitForIndexingForLoadingSources({
      type: "raw",
      sources: rawSources,
      setSources: setRawSources,
      pingSourcesLoaded: pingRawSourcesLoaded,
      setPingSourcesLoaded: setPingRawSourcesLoaded,
      chatbotId,
      token,
      setOpenIntervals,
      fetchUserInfo,
    });
  }, [rawSources, pingRawSourcesLoaded]);

  // Ping for deletion status
  useEffect(() => {
    if (deletingSources?.length > 0 && !pendingDeletionIsPingingRef.current) {
      console.log("Deleting...");
      pendingDeletionIsPingingRef.current = true;
      updatePendingDeletionStatus({
        chatbotId,
        token,
        setDeletingSources,
        pendingDeletionIsPingingRef,
        setOpenIntervals,
        fetchUserInfo,
      });
    }
  }, [deletingSources]);

  useEffect(() => handleActiveTabChange(), [tabActive]);

  useEffect(() => handleSourceTypeChange(), [sourceType]);

  // This is for changing the width of all kb type elements to match the longest one shown on screen
  useEffect(() => {
    if (sourcesToList?.length > 0) {
      const pinkElements = document.getElementsByClassName("type-pink");
      let maxLength = 0;

      // Find the maximum width among the elements
      for (let i = 0; i < pinkElements.length; i++) {
        if (pinkElements[i].innerText.length > maxLength) {
          maxLength = pinkElements[i].innerText.length;
        }
      }

      const charLength = 4.925;
      const maxWidth = 70 + charLength * maxLength;
      const typeElements =
        document.getElementsByClassName("kb-table-item type");
      // Set the maximum width to all the elements
      for (let i = 0; i < typeElements.length; i++) {
        typeElements[i].style["min-width"] = maxWidth + "px";
        typeElements[i].style["max-width"] = maxWidth + "px";
      }
    }
  }, [sourcesToList]);

  // Handles changes when tab is active vs when tab is not active
  const handleActiveTabChange = () => {
    if (!tabActive) {
      // Closes all open intervals when leaving tab
      closeOpenIntervals();
      return;
    }

    // Starts up intervals to ping api for change in status of indexing
    // Only does this when tab has been switched already because before then we already have them running
    if (tabActive && hasSwitchedTab) {
      if (sourceType === "Web") {
        setPingWebSourcesLoaded(true);

        pendingDeletionIsPingingRef.current = false;
        setDeletingSources((prev) => [...prev]);
      } else if (sourceType === "Pdf") setPingPdfSourcesLoaded(true);
      else if (sourceType === "Text") setPingTextSourcesLoaded(true);
    }
  };

  // Closes intervals for current source type and starts them up for the new current source type
  const handleSourceTypeChange = () => {
    closeOpenIntervals();

    // Start ping intervals for new source type
    if (sourceType === "Web") {
      setPingWebSourcesLoaded(true);

      pendingDeletionIsPingingRef.current = false;
      setDeletingSources((prev) => [...prev]);
    } else if (sourceType === "Pdf") setPingPdfSourcesLoaded(true);
    else if (sourceType === "Text") setPingTextSourcesLoaded(true);
  };

  const closeOpenIntervals = () => {
    openIntervals.current.forEach((openInterval) => {
      // clearInterval(openInterval);
      clearTimeout(openInterval);
    });

    if (openIntervals.length !== 0) setOpenIntervals((prev) => []);
  };

  const value = {
    chatbotId,
    token,

    sourceType,
    setSourceType,
    currentSources,
    setCurrentSources,
    rawSources,
    setRawSources,
    webSources,
    setWebSources,
    pdfSources,
    setPdfSources,
    textSources,
    setTextSources,

    openIntervals,
    setOpenIntervals,

    pingWebSourcesLoaded,
    setPingWebSourcesLoaded,

    pingPdfSourcesLoaded,
    setPingPdfSourcesLoaded,

    pingTextSourcesLoaded,
    setPingTextSourcesLoaded,

    pingRawSourcesLoaded,
    setPingRawSourcesLoaded,

    planCharLimitExceeded,

    deletingSources,
    setDeletingSources,
  };

  return (
    <KnowledgeBaseContext.Provider value={value}>
      {initialSourceCountRef.current === 0 &&
        currentSources?.length > 0 &&
        !hasRunGtagRef.current && (
          <EventTrigger
            triggerType="firstSource"
            customFunc={() => {
              hasRunGtagRef.current = true;
            }}
          />
        )}

      <div className="knowledge-base-container">
        <div id="knowledge-base-menu">
          <div className="side-tab-item-container title">
            <span>SOURCES</span>
          </div>

          <SideTab
            type="Web"
            selectedIcon={selectedWebsiteIcon}
            icon={websiteIcon}
          >
            Websites
          </SideTab>

          <SideTab type="Pdf" selectedIcon={selectedPdfIcon} icon={pdfIcon}>
            Documents
          </SideTab>

          <SideTab type="Text" selectedIcon={selectedTextIcon} icon={textIcon}>
            Text & FAQ
          </SideTab>

          <SideTab
            type="Starters"
            selectedIcon={selectedStartersIcon}
            icon={startersIcon}
          >
            Starters
          </SideTab>

          <SideTab type="Dropbox" selectedIcon={dropboxIcon} icon={dropboxIcon}>
            Dropbox
          </SideTab>
          <SideTab
            type="Google Drive"
            selectedIcon={googleDriveIcon}
            icon={googleDriveIcon}
          >
            Google Drive
          </SideTab>

          <SideTab type="Notion" selectedIcon={notionIcon} icon={notionIcon}>
            Notion
          </SideTab>

          {/* <SideTab
            type="Confluence"
            selectedIcon={confluenceIcon}
            icon={confluenceIcon}
          >
            Confluence
          </SideTab> */}

          <SideTab type="Box" selectedIcon={boxIcon} icon={boxIcon}>
            Box
          </SideTab>
        </div>

        <div className="knowledge-base">
          {sourceType === "Pdf" && (
            <span className="kb-title first">
              Add Document Sources{" "}
              <span style={{ fontWeight: "400" }}>
                (PDF, DOCX, or TXT files)
              </span>
            </span>
          )}
          {sourceType !== "Pdf" && sourceType !== "Starters" && (
            <span className="kb-title first">
              Add a {sourceType === "Pdf" ? "Document" : sourceType} Source
            </span>
          )}

          {sourceType === "Web" && (
            <div className="sources">
              <AddSourceButton
                text="Single Page URL"
                type="web"
                subType="Single Page"
              />
              <AddSourceButton
                text="Crawl Website"
                type="web"
                subType="Website"
              />
              {/* <AddSourceButton
                text="Crawl Sitemap"
                type="web"
                subType="Sitemap"
              /> */}
            </div>
          )}
          {sourceType === "Pdf" && (
            <div className="sources">
              <AddSourceButton
                text="Upload Files"
                type="pdf"
                subType="upload"
              />
            </div>
          )}
          {sourceType === "Text" && (
            <div className="sources">
              <AddSourceButton text="Text Entry" type="text" />
            </div>
          )}

          {sourceType === "Notion" && (
            <ExternalSource
              dataSource="NOTION"
              dataSourceLabel="Notion"
              rawSources={rawSources}
              token={token}
              chatbotId={chatbotId}
              setRawSources={setRawSources}
              fetchContentItems={refetch}
              fetchContentItemsLoading={isLoading}
            />
          )}
          {sourceType === "Dropbox" && (
            <ExternalSource
              dataSource="DROPBOX"
              dataSourceLabel="Dropbox"
              rawSources={rawSources}
              token={token}
              chatbotId={chatbotId}
              setRawSources={setRawSources}
              fetchContentItems={refetch}
              fetchContentItemsLoading={isLoading}
            />
          )}
          {sourceType === "Google Drive" && (
            <ExternalSource
              dataSource="GOOGLE_DRIVE"
              dataSourceLabel="Google Drive"
              rawSources={rawSources}
              token={token}
              chatbotId={chatbotId}
              setRawSources={setRawSources}
              fetchContentItems={refetch}
              fetchContentItemsLoading={isLoading}
            />
          )}
          {sourceType === "Box" && (
            <ExternalSource
              dataSource="BOX"
              dataSourceLabel="Box"
              rawSources={rawSources}
              token={token}
              chatbotId={chatbotId}
              setRawSources={setRawSources}
              fetchContentItems={refetch}
              fetchContentItemsLoading={isLoading}
            />
          )}
          {/* {sourceType === "Confluence" && (
            <ExternalSource
              dataSource="CONFLUENCE"
              dataSourceLabel="Confluence"
              rawSources={rawSources}
              token={token}
              chatbotId={chatbotId}
              setRawSources={setRawSources}
              fetchContentItems={refetch}
              fetchContentItemsLoading={isLoading}
            />
          )} */}

          {!["Starters", ...externalSources].includes(sourceType) && (
            <span className="kb-title" style={{ marginTop: "11px" }}>
              Current {sourceType === "Pdf" ? "Document" : sourceType} Sources
            </span>
          )}

          {areSourcesFetched &&
          !["Starters", ...externalSources].includes(sourceType) ? (
            <table className="scrollable-table knowledge-base-table">
              <tr className="kb-tr">
                <th className="kb-side-table-item">
                  <div className="row-align-center first-column-styles">
                    <img src={loadingPurple} style={{ visibility: "hidden" }} />
                    <span
                      className="table-icon-next-to-text"
                      style={{ marginLeft: "8px" }}
                    >
                      {sourceType === "Web" ? "URLs" : "Document Title"}
                    </span>
                  </div>
                </th>
                <th className="kb-table-item type">Type</th>
                <th className="kb-table-item date">Index date</th>
                <th
                  className={`kb-table-item actions ${
                    ["Pdf", "Text"].includes(sourceType) && "small"
                  }`}
                >
                  Actions
                </th>
              </tr>

              {sourcesToList &&
                sourcesToList.map((source, index) => (
                  <KnowledgeBaseTableRow source={source} key={source.id} />
                ))}
            </table>
          ) : !["Starters", ...externalSources].includes(sourceType) ? (
            <WaitingWithText>
              <span className="kb-title">Loading Sources</span>
            </WaitingWithText>
          ) : null}

          {sourceType === "Starters" && areSourcesFetched && (
            <KBStarters chatbot={chatbot} setChatbot={setChatbot} />
          )}

          {/* Message for when char limit has been reached */}
          <LimitReachedMessage
            userInfo={userInfo}
            chatbot={chatbot}
            planCharLimitExceeded={planCharLimitExceeded}
            sourceType={sourceType}
          />
        </div>
      </div>
    </KnowledgeBaseContext.Provider>
  );
}

const SideTab = ({ selectedIcon, icon, type, children }) => {
  const { sourceType, setSourceType } = useContext(KnowledgeBaseContext);

  return (
    <button
      className={`side-tab-item-container ${
        sourceType === type ? "selected" : ""
      }`}
      onClick={() => setSourceType(type)}
    >
      <img
        className="side-tab-item-icon"
        src={sourceType === type ? selectedIcon : icon}
        alt={`${type} icon`}
      />
      <span>{children}</span>
    </button>
  );
};

const LimitReachedMessage = ({
  userInfo,
  chatbot,
  sourceType,
  planCharLimitExceeded,
}) => {
  // Message for when char limit has been reached
  const charsUsed = (
    Number(chatbot?.used_character_index_limit) / 2000
  ).toFixed(1);
  const planCharsLimit =
    Number(
      userInfo?.plan_information?.plan_supported_features?.usage
        ?.character_index_limit
    ) / 2000;

  const showCharLimitExceededMessage =
    planCharLimitExceeded && sourceType !== "Starters";

  return showCharLimitExceededMessage ? (
    <span
      style={{
        marginTop: "11px",
        fontSize: "14px",
        fontWeight: "400",
      }}
    >
      Content pages limit exceeded:{" "}
      <span style={{ fontWeight: "600", color: "#cf212e" }}>{charsUsed}</span>{" "}
      (of {planCharsLimit}
      )
      <br />
      Some of your sources were not processed as a result.
      <br />
      Please{" "}
      <Link
        to="/plan"
        className="link-on-hover link"
        style={{ fontWeight: "500" }}
      >
        upgrade your plan
      </Link>{" "}
      to increase the limit, or remove some sources.
    </span>
  ) : (
    ""
  );
};

export const formatSourceStateString = (stateString) => {
  stateString = stateString.charAt(0).toUpperCase() + stateString.slice(1);
  stateString = stateString.replace(/_/g, " ");
  return stateString;
};
