import { useContext, useEffect, useState } from "react";
import { WrappedComponentContext } from "../../WrappedComponent";
import useConnectionsQuery from "./queries/useConnectionsQuery";

const useFetchConnection = ({ token, chatbotId, dataSource, connect }) => {
  const [connection, setConnection] = useState();
  const { setBannerMessage } = useContext(WrappedComponentContext);
  const { data, loading, refetch, isRefetching } = useConnectionsQuery({
    chatbotId,
    dataSource,
    token,
  });

  useEffect(() => {
    if (!data) return;
    const dataSourceConnection = data.find(
      (item) => item.data_source_type === dataSource
    );

    if (!dataSourceConnection) return;

    if (dataSourceConnection.sync_status === "SYNC_ERROR") {
      setBannerMessage({
        type: "error",
        title: `This data source is not synced properly. Resync or disconnect and connect again.`,
      });
    }

    setConnection(dataSourceConnection);

    const splitString = dataSourceConnection.data_source_external_id.includes(
      "|"
    )
      ? "|"
      : "-";

    const splitExternalId =
      dataSourceConnection.data_source_external_id.split(splitString);

    if (!splitExternalId[1]) return;
    const email = splitExternalId[1];
    connect(email);
  }, [connect, data, dataSource, setBannerMessage]);

  return { connection, loading, refetch, isRefetching };
};

export default useFetchConnection;
