export const extractSources = ({
  sources,
  setWebSources,
  setPdfSources,
  setTextSources,
  setRawSources,
  setPingWebSourcesLoaded,
  setPingPdfSourcesLoaded,
  setPingTextSourcesLoaded,
  setPingRawSourcesLoaded,
}) => {
  extractWebSources({ sources, setWebSources, setPingWebSourcesLoaded });
  extractPdfSources({ sources, setPdfSources, setPingPdfSourcesLoaded });
  extractTextSources({ sources, setTextSources, setPingTextSourcesLoaded });
  extractRawSources({ sources, setRawSources, setPingRawSourcesLoaded });
};

const extractWebSources = ({
  sources,
  setWebSources,
  setPingWebSourcesLoaded,
}) => {
  // Extracts all web sources from list of all sources

  let webSources = sources.filter(
    (source) =>
      !source.type.startsWith("ContentItems::Documents") &&
      source.type !== "ContentItems::RawTextContentItem"
  );

  webSources = webSources.map((webContentItem) => {
    const url = webContentItem?.content?.site_url;
    const subtype =
      webContentItem?.type === "ContentItems::SinglePageUrlContentItem"
        ? "Single Page"
        : "Website";
    const discovered_urls = webContentItem?.content?.discovered_url_infos;
    const date = webContentItem?.updated_at;
    const webSource = {
      ...webContentItem,
      url,
      subtype,
      discovered_urls,
      date,
    };
    return webSource;
  });

  setWebSources([...webSources]);
  setPingWebSourcesLoaded(true); // For indicating to the user which sources need indexing
};

const extractPdfSources = ({
  sources,
  setPdfSources,
  setPingPdfSourcesLoaded,
}) => {
  // Extracts all pdf sources from list of all sources

  let pdfSources = sources
    .filter((source) => source.type.startsWith("ContentItems::Documents"))
    .filter((source) => !source.type.includes("CarbonAi"))
    .filter((source) => !source.carbon_item_type);

  pdfSources = pdfSources.map((pdfContentItem) => {
    const date = pdfContentItem?.updated_at;
    const pdfSource = { ...pdfContentItem, date };
    return pdfSource;
  });

  setPdfSources([...pdfSources]);
  setPingPdfSourcesLoaded(true); // For indicating to the user which sources need indexing
};

const extractTextSources = ({
  sources,
  setTextSources,
  setPingTextSourcesLoaded,
}) => {
  // Extracts all text sources from list of all sources

  let textSources = sources.filter(
    (source) => source.type === "ContentItems::RawTextContentItem"
  );

  textSources = textSources.map((textContentItem) => {
    const text = textContentItem?.content?.raw_text;
    const date = textContentItem?.updated_at;
    const textSource = { ...textContentItem, text, date };
    return textSource;
  });

  setTextSources([...textSources]);
  setPingTextSourcesLoaded(true); // For indicating to the user which sources need indexing
};

const extractRawSources = ({
  sources,
  setRawSources,
  setPingRawSourcesLoaded,
}) => {
  const newSources = sources
    .filter((source) => source.type.includes("CarbonAi"))
    .map((source) => {
      const date = source?.updated_at;
      const newSource = { ...source, date };
      return newSource;
    });

  setRawSources([...newSources]);
  setPingRawSourcesLoaded(true); // For indicating to the user which sources need indexing
};
