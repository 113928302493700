import { ReactComponent as FileIcon } from "../../../media/fileIcon.svg";
import { ReactComponent as CloseIcon } from "../../../media/close2.svg";
import Loader from "../Loader/Loader";
import { DATA_SOURCES_WITH_PAGES } from "../../store";
import RowLoader from "../RowLoader/RowLoader";

const ReviewScreen = ({ dataSource, store }) => {
  const hasPagesInsteadOfFiles = DATA_SOURCES_WITH_PAGES.includes(dataSource);
  const { checkedFiles, checkUncheckFile, setReviewMode, isLoadingFiles } =
    store();

  const onCheckFile = (isChecked, file) => {
    checkUncheckFile(isChecked, file);
  };

  const onRemoveAll = () => {
    setReviewMode(false);
  };

  return (
    <>
      <h2
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#5F5968",
          display: "flex",
          gap: "6px",
        }}
      >
        Selected {hasPagesInsteadOfFiles ? "Pages" : "Files"}
        {isLoadingFiles && <Loader />}
      </h2>
      <div
        style={{
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        {checkedFiles.map((file) => {
          return (
            <div
              key={file.id}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                background: "#F6F7F9",
                borderBottom: "1px solid #D7D7D7",
                padding: "12px",
                maxHeight: "40px",
                boxSizing: "border-box",
                color: "#1E1E1E",
                fontWeight: 500,
              }}
            >
              <FileIcon />
              {file.name}
              <CloseIcon
                onClick={() => {
                  onCheckFile(true, file);

                  if (checkedFiles.length === 1) {
                    onRemoveAll();
                  }
                }}
                style={{
                  marginLeft: "auto",
                  cursor: "pointer",
                  padding: "6px",
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ReviewScreen;
